import {
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  DataGridProps,
} from "@fluentui/react-components";
import { Building24Regular } from "@fluentui/react-icons";
import { OutlookProject, OutlookProjects } from "../../models/v1";
import React from "react";

interface ProjectGridProps {
  projects: OutlookProjects;
}

const columns: TableColumnDefinition<OutlookProject>[] = [
  createTableColumn<OutlookProject>({
    columnId: "project_id",
    compare: (a, b) => {
      return a.id.localeCompare(b.id);
    },
    renderHeaderCell: () => {
      return "Project ID";
    },
    renderCell: (project) => {
      return (
        <TableCellLayout media=<Building24Regular />>
          {project.id}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<OutlookProject>({
    columnId: "project_name",
    compare: (a, b) => {
      return a.name.localeCompare(b.name);
    },
    renderHeaderCell: () => {
      return "Name";
    },
    renderCell: (project) => {
      return project.name;
    },
  }),
  createTableColumn<OutlookProject>({
    columnId: "project_postal_code",
    compare: (a, b) => {
      return a.address_components.postal_code.localeCompare(
        b.address_components.postal_code,
      );
    },
    renderHeaderCell: () => {
      return "Postal Code";
    },
    renderCell: (project) => {
      return project.address_components.postal_code;
    },
  }),
  createTableColumn<OutlookProject>({
    columnId: "project_address",
    compare: (a, b) => {
      return a.address_components.street_address.localeCompare(
        b.address_components.street_address,
      );
    },
    renderHeaderCell: () => {
      return "Address";
    },
    renderCell: (project) => {
      return project.address_components.street_address;
    },
  }),
];

const ProjectGrid = ({ projects }: ProjectGridProps) => {
  const [sortState, setSortState] = React.useState<
    Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
  >({
    sortColumn: "file",
    sortDirection: "ascending",
  });
  const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
    e;
    setSortState(nextSortState);
  };

  return (
    <DataGrid
      items={projects.projects}
      columns={columns}
      sortable
      sortState={sortState}
      onSortChange={onSortChange}
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<OutlookProject>>
        {({ item, rowId }) => (
          <DataGridRow<OutlookProject> key={rowId}>
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};

export default ProjectGrid;
