import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@fluentui/react-components";
import { AddCircle24Filled } from "@fluentui/react-icons";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { useMemo } from "react";

import { connectAxios } from "../../utils/axios";
import {
  OutlookProjects,
  OutlookSignUpOrSignInResponse,
} from "../../models/v1";
import ProjectGrid from "./ProjectGrid";

import { CompoundButton } from "@fluentui/react-components";
import NewProjectForm from "./NewProjectForm";

/* global Office, console */

interface AppProps {
  msalInstance: IPublicClientApplication;
}

export interface AppState {
  authStatus?: string;
  fileFetch?: string;
  headerMessage?: string;
  errorMessage?: string;
}

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

// We have two basic views within the app:
// 1. Show a list of projects owned by the user
// 2. Show an interface to create a new project entry for the user
enum AppStates {
  SHOW_PROJECT_LIST = "show_project_list",
  SHOW_CREATE_PROJECT = "show_create_project",
}

const App = ({ msalInstance }: AppProps) => {
  const styles = useStyles();

  const [currentState, setCurrentState] = useState<AppStates>(
    AppStates.SHOW_PROJECT_LIST,
  );
  const [authHeader, setAuthHeader] = useState<string>("");
  const [timestamp, setTimestamp] = useState<number>(0);
  const [projects, setProjects] = useState<OutlookProjects>({ projects: [] });
  const axios = connectAxios({ debug: true });

  useMemo(async () => {
    const now = Date.now();
    // Attempt renewal every 30 seconds.
    if (now - timestamp > 30000) {
      const authToken = await Office.auth.getAccessToken({
        allowSignInPrompt: true,
        allowConsentPrompt: true,
      });
      const authHeader: string = "Bearer " + authToken;
      const axios = connectAxios({ debug: true });
      try {
        const loginResponse = await axios.post(
          "/v1/login/outlook_sign_up_or_sign_in",
          "",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: authHeader,
            },
          },
        );
        const outlookResponse = OutlookSignUpOrSignInResponse.parse(
          loginResponse["data"],
        );
        console.log("LOGIN RESPONSE: " + outlookResponse.status);
      } catch (error) {
        console.error("Login error: " + error);
      }
      setAuthHeader(authHeader);
      setTimestamp(now);
    }
  }, [axios]);

  useMemo(async () => {
    try {
      const projectsResponse = await axios.get(
        "/v1/microsoft_outlook/project",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
        },
      );
      setProjects(OutlookProjects.parse(projectsResponse.data));
    } catch (error) {
      console.error("/GET projects error: " + error);
    }
  }, [authHeader, currentState]);

  if (currentState === AppStates.SHOW_CREATE_PROJECT) {
    return (
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Popup}
          authenticationRequest={{
            // TODO(databurro): Is this the correct scope list?
            scopes: ["https://graph.microsoft.com/User.Read"], // Add more permissions in this array as needed.
            prompt: "login",
          }}
          loadingComponent={LoadingComponent}
        >
          <AuthenticatedTemplate>
            <div className={styles.root}>
              <NewProjectForm
                axios={axios}
                authHeader={authHeader}
                onSubmitClick={async () => {
                  const projectsResponse = await axios.get(
                    "/v1/microsoft_outlook/project",
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: authHeader,
                      },
                    },
                  );
                  setProjects(OutlookProjects.parse(projectsResponse.data));
                  setCurrentState(AppStates.SHOW_PROJECT_LIST);
                }}
                onCancelClick={async () => {
                  setCurrentState(AppStates.SHOW_PROJECT_LIST);
                }}
              ></NewProjectForm>
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>This will only render if a user is not signed-in.</p>
          </UnauthenticatedTemplate>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    );
  } else {
    return (
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Popup}
          authenticationRequest={{
            scopes: ["https://graph.microsoft.com/User.Read"], // Add more permissions in this array as needed.
            prompt: "login",
          }}
          loadingComponent={LoadingComponent}
        >
          <AuthenticatedTemplate>
            <div className={styles.root}>
              <ProjectGrid projects={projects}></ProjectGrid>
              <CompoundButton
                icon=<AddCircle24Filled />
                secondaryContent="New project"
                appearance="subtle"
                size="large"
                onClick={() => setCurrentState(AppStates.SHOW_CREATE_PROJECT)}
              ></CompoundButton>
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>This will only render if a user is not signed-in.</p>
          </UnauthenticatedTemplate>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    );
  }
};

export default App;
