import * as React from "react";
import { AxiosInstance } from "axios";
import { useState } from "react";
import { CompoundButton } from "@fluentui/react-components";
import {
  Checkmark24Regular,
  DismissCircle24Regular,
} from "@fluentui/react-icons";

/* global console */

interface NewProjectFormProps {
  axios: AxiosInstance;
  authHeader: string;
  onSubmitClick: () => {};
  onCancelClick: () => {};
}

const NewProjectForm: React.FC<NewProjectFormProps> = ({
  axios,
  authHeader,
  onSubmitClick,
  onCancelClick,
}) => {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const projectsResponse = await axios.post(
        "/v1/microsoft_outlook/project",
        JSON.stringify({
          id: id,
          name: name,
          address: address,
          description: description,
          address_components: {
            postal_code: postalCode,
            street_address: address,
          },
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
        },
      );
      console.log("Response: " + projectsResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Project ID:
        <input
          type="text"
          name="project_id"
          value={id}
          onChange={(e) => {
            setId(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Project name:
        <input
          type="text"
          name="project_name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Project address:
        <input
          type="text"
          name="project_address"
          value={address}
          onChange={(e) => {
            const matches = e.target.value.match(new RegExp(",?([0-9]{4})"));
            if (matches !== null && matches !== undefined) {
              if (matches.length > 0) {
                // matches[0] is the whole matched string. Take the first group,
                // at index 1.
                setPostalCode(matches[1]);
              }
            }
            setAddress(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Project description:
        <input
          type="text"
          name="project_description"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </label>
      <CompoundButton
        icon=<Checkmark24Regular />
        secondaryContent="Add project"
        appearance="subtle"
        size="large"
        disabled={id === "" || name === "" || address === ""}
        onClick={(e) => {
          handleSubmit(e);
          onSubmitClick();
        }}
      ></CompoundButton>
      <CompoundButton
        icon=<DismissCircle24Regular />
        secondaryContent="Cancel"
        appearance="subtle"
        size="large"
        onClick={onCancelClick}
      ></CompoundButton>
    </form>
  );
};

export default NewProjectForm;
