// Generated by src/benetics/tools/typescript.py. DO NOT EDIT.
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */

import {z} from 'zod';

/** Status for a call to /outlook_sign_up_or_sign_in. */
export const OutlookSignUpOrSignInStatus = z.string().pipe(z.enum([
  'user_created',
  'user_logged_in',
  'UNKNOWN_OUTLOOK_SIGN_UP_OR_SIGN_IN_STATUS',
]).catch('UNKNOWN_OUTLOOK_SIGN_UP_OR_SIGN_IN_STATUS'));
export type OutlookSignUpOrSignInStatus = z.TypeOf<typeof OutlookSignUpOrSignInStatus>;

/** Response for initiation of a Connect user session. */
export const OutlookSignUpOrSignInResponse = z.object({
  status: OutlookSignUpOrSignInStatus,
});
export type OutlookSignUpOrSignInResponse = z.TypeOf<typeof OutlookSignUpOrSignInResponse>;

/** Shadows AddressComponents from `benetics.connect.db.microsoft.models.projects`. */
export const AddressComponents = z.object({
  place_id: z.string().nullish(),
  latitude: z.string().nullish(),
  longitude: z.string().nullish(),
  street_address: z.string().nullish(),
  postal_code: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
});
export type AddressComponents = z.TypeOf<typeof AddressComponents>;

/** API representation of an Outlook project. */
export const OutlookProject = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  address: z.string(),
  address_components: AddressComponents,
  avatar_bitmap: z.string().nullish(),
});
export type OutlookProject = z.TypeOf<typeof OutlookProject>;

/** List of outlook projects for a user. */
export const OutlookProjects = z.object({
  projects: z.array(OutlookProject),
});
export type OutlookProjects = z.TypeOf<typeof OutlookProjects>;

/** Status result of a request to create an Outlook project. */
export const OutlookProjectCreationStatus = z.string().pipe(z.enum([
  'project_created',
  'project_exists',
  'UNKNOWN_OUTLOOK_PROJECT_CREATION_STATUS',
]).catch('UNKNOWN_OUTLOOK_PROJECT_CREATION_STATUS'));
export type OutlookProjectCreationStatus = z.TypeOf<typeof OutlookProjectCreationStatus>;

/** Result of a request to create an Outlook project. */
export const OutlookProjectCreated = z.object({
  status: OutlookProjectCreationStatus,
});
export type OutlookProjectCreated = z.TypeOf<typeof OutlookProjectCreated>;

